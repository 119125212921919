import React, { useState, createContext } from "react";

const defaultState = {
  closePageSteps: 0,
  setClosePageSteps: () => {},
  listSort: null,
  setListSort: () => {},
};

export const FigContext = createContext(defaultState);

const Provider = (props) => {
  const [closePageSteps, setClosePageSteps] = useState(0);
  const [listSort, setListSort] = useState(null);

  return (
    <FigContext.Provider
      value={{
        closePageSteps,
        setClosePageSteps,
        listSort,
        setListSort,
      }}
    >
      {props.children}
    </FigContext.Provider>
  );
};

export default Provider;
